import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Sidebar from '../components/sidebar';
import Breadcrumbs from '../components/breadcrumbs';
import Tiles from '../components/tiles'
import Footer from '../components/footer';
import Link from '../components/link';

export const projectQuery = graphql`
  query($slug: String!) {
    project: datoCmsArticle(slug: { eq: $slug }) {
      order
      title
      subtitle
      mainArticle {
        slug
        title
      }
      tiles {
        order
        title
        subtitle
        description
        slug
      }
      textNode {
        childMarkdownRemark {
          html
        }
      }
      content {
        title
        subtitle
        date
        generalNode {
          childMarkdownRemark {
            html
          }
        }
        earlyNode {
          childMarkdownRemark {
            html
          }
        }
        fixesNode {
          childMarkdownRemark {
            html
          }
        }
        applicationsNode {
          childMarkdownRemark {
            html
          }
        }
        weeklyNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const Content = ({ title, subtitle, date, generalNode, earlyNode, fixesNode, applicationsNode, weeklyNode }) => {
  const general = generalNode.childMarkdownRemark.html;
  const early = earlyNode.childMarkdownRemark.html;
  const fixes = fixesNode.childMarkdownRemark.html;
  const applications = applicationsNode.childMarkdownRemark.html;
  const weekly = weeklyNode.childMarkdownRemark.html;

  return (
    <div className="">
      <h3>{title}</h3>
      <p className="text-t5 mb-4">{subtitle}</p>
      <p className="text-t5 text-orange">* Features may not be available in all Okta Product SKUs.</p>
      <div className="pl-6 border-l-8 border-gray-200 my-12">
        <h5 className="mb-6">Content</h5>
        <div className="flex flex-col">
          <Link to={`#general-${date}`} className="mb-2">Generally available features</Link>
          <Link to={`#early-${date}`} className="mb-2">Early access features</Link>
          <Link to={`#fixes-${date}`} className="mb-2">Fixes</Link>
          <Link to={`#applications-${date}`} className="mb-2">Applications</Link>
          <Link to={`#weekly-${date}`} className="mb-2">Weekly updates</Link>
        </div>
      </div>

      <div id={`general-${date}`} className="my-8" dangerouslySetInnerHTML={ { __html: general } } />
      <div id={`early-${date}`} className="my-8" dangerouslySetInnerHTML={ { __html: early } } />
      <div id={`fixes-${date}`} className="my-8" dangerouslySetInnerHTML={ { __html: fixes } } />
      <div id={`applications-${date}`} className="my-8" dangerouslySetInnerHTML={ { __html: applications } } />
      <div id={`weekly-${date}`} className="my-8" dangerouslySetInnerHTML={ { __html: weekly } } />
    </div>
  )
}

const Article = ({ location, data }) => {
  const { order, title, subtitle, mainArticle, tiles, content, textNode: { childMarkdownRemark: { html } } } = data.project;
  const path = location.pathname ? location.pathname : '';
  const [contentIndex, setContentIndex] = useState(0);

  return (
    <Layout>
      <main className="flex">
        <Sidebar path={path} />

        <div className="flex flex-col justify-between w-full">
          <div className="">
            <Breadcrumbs mainArticle={mainArticle} />
            <article className="article px-6 pt-4 pb-8">
              <h1 className="text-t1 font-medium mb-6">{order === 1 ? title : subtitle}</h1>
              { !!(tiles && tiles.length) && <Tiles products={tiles} /> }
              {
                !!(content && content.length > 1) && (
                  <div className="flex border-b border-gray-200 mb-8">
                    {
                      content.map((version, i) => 
                        <button
                          key={i}
                          onClick={() => setContentIndex(i)}
                          className={`${i === contentIndex ? " border-blue-500" : "border-transparent"} border-b-3 focus:outline-none cursor-pointer px-6 py-4 font-medium text-gray-900`}
                        >
                          {version.title}
                        </button>
                      )
                    }
                  </div>
                )
              }
              { !!(content && content.length) && <Content {...content[contentIndex]} />}
              <div dangerouslySetInnerHTML={ { __html: html } } />
            </article>
          </div>
          
          <Footer />
        </div>
      </main>
    </Layout>
  );
};

export default Article;
